<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="mainBox">
      <!-- 控制区域 -->
      <div class="control_box">
        <Search-bar :inputs="inputs" :selects="selects" @search="search" @reset="search" />
        <div class="control_box_bottom">
          <a-button type="primary" icon="printer" @click="downloadExcel" :disabled="tableData.length == 0">导出</a-button>
        </div>
      </div>
      <!-- 内容区域 -->
      <div class="show_box">
        <a-table :columns="columns" :data-source="tableData" :loading="loading" :rowKey="(tableData) => tableData.id"
          :pagination="pagination" @change="handleTableChange" />
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getEstimate, mealtimeListWithout, officesWithout,
  postsWithout,
  userLog
} from "@/request/api";
//引入封装的组件
import SearchBar from "@/components/common/SearchBar.vue";
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { SearchBar, MyBreadcrumb },
  data () {
    return {
      breadcrumbs: [
        {
          key: 0,
          title: "用餐管理",
        },
        {
          key: 1,
          title: "评论列表",
        },
      ],
      inputs: [
        {
          placeholder: "姓名",
          model: "name",
          width: 100,
        }
      ],
      selects: [
        {
          model: "office",
          placeholder: "部门",
          width: 200,
          options: [
            {
              key: "",
              value: "部门",
            },
          ],
        },
        {
          model: "post",
          placeholder: "职务",
          width: 100,
          options: [
            {
              key: "",
              value: "职务",
            },
          ],
        },
        {
          model: "meal",
          placeholder: "用餐时段",
          width: 100,
          options: [
            {
              key: "",
              value: "用餐时段",
            },
          ],
        },
        {
          model: "type",
          placeholder: "点餐",
          width: 100,
          options: [
            {
              key: 2,
              value: "点餐",
            },
            {
              key: 1,
              value: "套餐",
            },
          ],
        },
      ],
      queryParam: {
        page: 1,
        post: "",
        office: "",
        name: "",
        type: 2,
        meal: ""
      },
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      officesData: [],
      postsData: [],
      dataForExport: [],
      loading: false,
      columns: [
        {
          title: "用餐时段",
          key: "meal_title",
          dataIndex: "meal_title",
        },
        {
          title: "部门",
          key: "office",
          dataIndex: "office",
        },
        {
          title: "职务",
          key: "post",
          dataIndex: "post",
        },
        {
          title: "姓名",
          key: "realname",
          dataIndex: "realname",
        },
        {
          title: "用餐日期",
          key: "repast_date",
          dataIndex: "repast_date",
        },
        {
          title: "星级",
          key: "starlevel",
          dataIndex: "starlevel",
        },
        {
          title: "评价",
          key: "estimate",
          dataIndex: "estimate",
        },
        // {
        //   title: "操作",
        //   key: "action",
        //   scopedSlots: { customRender: "action" },
        // },
      ], // 表格的列配置
      pagination: {
        total: 0,
        pageSize: 15,
        current: 1,
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //列表下载
    downloadExcel () {
      this.$confirm({
        title: "确定导出表格?",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          this.export2Excel();
        },
        onCancel: () => { },
      });
    },
    //数据写入excel
    export2Excel () {
      var that = this;
      require.ensure([], () => {
        const { export_json_to_excel } = require("@/excel/export2Excel"); //这里必须使用绝对路径，使用@/+存放export2Excel的路径
        const tHeader = [
          "用餐时段",
          "部门",
          "职务",
          "姓名",
          "用餐日期",
          "星级",
          "评价"
        ]; // 导出的表头名信息
        const filterVal = [
          "meal_title",
          "office",
          "post",
          "realname",
          "repast_date",
          "starlevel",
          "estimate"
        ]; // 导出的表头字段名，需要导出表格字段名
        const list = that.dataForExport;
        const data = that.formatJson(filterVal, list);

        export_json_to_excel(tHeader, data, "评论列表"); // 导出的表格名称，根据需要自己命名
        this.userLogFn()
      });
    },
    //格式转换
    formatJson (filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    // 刷新
    search (val) {
      this.queryParam = {
        page: 1,
        office: val.select.office ? val.select.office : "",
        post: val.select.post ? val.select.post : "",
        meal: val.select.meal ? val.select.meal : "",
        type: val.select.type ? val.select.type : 2,
        name: val.input.name ? val.input.name : "",
      };
      this.pagination.current = 1;
      this.getTableData();
    },

    //获取表格数据
    getTableData () {
      this.loading = true;
      //获取分页的数据用于展示
      this.queryParam.no_paging = 0;
      getEstimate(this.queryParam).then((res) => {
        const pagination = { ...this.pagination };
        pagination.total = res.data.total;
        this.pagination = pagination;
        if (res.data.data.length == 0) {
          this.loading = false;
          this.tableData = [];
          return;
        }
        this.tableData = res.data.data
        this.loading = false;
      });

      //请求不分页的数据
      this.queryParam.no_paging = 1;
      getEstimate(this.queryParam).then((res) => {
        this.dataForExport = res.data;
      });
    },

    //分页
    handleTableChange (pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.getTableData();
    },

    //获取就餐时段列表
    mealtimeListFn () {
      mealtimeListWithout({ no_paging: 1 }).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          this.selects[2].options.push({
            key: res.data[i].id,
            value: res.data[i].title,
          });
        }
      });
    },
    //获取部门列表
    getOffices () {
      officesWithout({ no_paging: 1 }).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          this.selects[0].options.push({
            key: res.data[i].id,
            value: res.data[i].title,
          });
        }
      });
    },

    //获取职务列表
    getPosts () {
      postsWithout({ no_paging: 1 }).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          this.selects[1].options.push({
            key: res.data[i].id,
            value: res.data[i].title,
          });
        }
      });
    },

    userLogFn(){
      let moduleId = this.$route.meta.moduleId,
          param = {
            name:this.queryParam.name,
            office:this.queryParam.office ? this.selects[0].options.find(item => item.key === this.queryParam.office).value : '',
            post:this.queryParam.post ? this.selects[1].options.find(item => item.key == this.queryParam.post).value : '',
            meal:this.queryParam.meal ? this.selects[2].options.find(item => item.key === this.queryParam.meal).value : '',
            type:this.queryParam.type ? this.selects[3].options.find(item => item.key === this.queryParam.type).value : '',
            total:this.dataForExport.length
          };
      userLog(moduleId,param).then(res => {}).catch(err => {
        console.log(err)
      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getTableData();
    this.mealtimeListFn();
    this.getOffices();
    this.getPosts();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainBox {
  .control_box {
    background-color: white;
    padding: 20px;
    .control_box_bottom {
      margin-top: 20px;
    }
  }
  .show_box {
    width: 100%;
    padding-top: 10px;
  }
}
</style>